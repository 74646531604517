  .default-page {
    height: 100%;
    background-color: $custom-black;
    position: relative;
    color: $custom-white;

    h2 {
      display: none;
    }
    
    @keyframes spin {  
      from {
        transform: rotate(0deg);
        opacity: 0;
      }  
      to {
        transform: rotate(360deg);
        opacity: 1;
      }  
    }

    .default-content {
      box-sizing: border-box;
      padding: $spacing-m $spacing-m;
      color: $custom-white;
      text-align: left;
      height: 50vh;
      position: absolute;
      z-index: 3;
      bottom: 2px;

      h1 {
        color: $custom-white;
        margin: 0;
        padding: 0 0 $spacing-m 0;
        font-size: $font-l;
        text-align: left;
      }

      p {
        padding: 0 $spacing-sm $spacing-sm 0;
      }

      .scroll-content {
        height: 34vh;
        overflow: scroll;
        overflow-y: visible;
        margin: 0 0 $spacing-m 0;

        ul {
          list-style-type: square;
          padding-left: $spacing-m;
        }
      }
    }
}

@media only screen and (min-width: $bp-mobile-max) {
  .default-page {
    h2 {
      display: block;
      position: absolute;
      bottom: $spacing-m;
      left: $spacing-l;
      color: $custom-white;
      opacity: 0.8;
      font-size: 6em;
      padding: 0px;
      margin: 0px;
    }

    .default-content {
      position: absolute;
      z-index: 3;
      top: $spacing-m;
      right: $spacing-ml;
      width: 400px;
      text-align: left;
      margin: 0;
      height: 60%;
      background-color: rgb(0,0,0, 0.65);

      h1 {
        padding: 0 0 $spacing-m 0;
      }

      .scroll-content {
        height: 51vh;
        overflow: scroll;
        overflow-y: visible;

        ul {
          list-style-type: square;
          padding-left: $spacing-m;
        }
      }

    }
  }
}
