@import '../../styles/variables.scss';

.social-media-links-container {
  position: absolute;
  top: $spacing-xs;
  right: $spacing-m;
  z-index: 30;

  ul {
    list-style: none;
    display: flex;

    li {
      margin: 0 0 0 $spacing-m;
      filter: grayscale(100%);
      transition: filter $transition-timing ease-in-out;

      img {
        display: inline-block;
        width: $spacing-ml;
        height: $spacing-ml;
      }

      &:hover {
       filter: grayscale(0%);
      }
    }
  }
}

@media only screen and (min-width: $bp-mobile-max) {
  .social-media-links-container {
    position: fixed;
    bottom: $spacing-l;
    right: $spacing-l;
    top: unset;

    ul li img {
      width: $spacing-xl;
      height: $spacing-xl; 
    }
  }
}