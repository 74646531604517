.music-page {
  border-top: 2px solid $custom-white; 
  padding: $spacing-xxl $spacing-m;
  text-align: center;
  background: $custom-black;
  color: $custom-white;

  .mix-content-container {
    .modal-container {
      padding: $spacing-xxl $spacing-l $spacing-l;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        font-size: $font-l;
        margin: 0 auto $spacing-l;
      }

      img {
        display: none;
        width: 70%;
        margin-bottom: $spacing-l;
      }
    }
  }
}

@media only screen and (min-width: $bp-mobile-max) {
  .mix-content-container {
    margin: $spacing-m auto 0;
    max-width: 90%;

    .modal-container {
      padding: $spacing-xxl $spacing-m $spacing-m;

      img {
        width: 30%;
        display: inline-flex;
        margin-bottom: $spacing-l;
      }
    }
  }
}
