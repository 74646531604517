@import './variables.scss';
@import './Scrolls.scss';
@import './DefaultPage.scss';
@import './MusicMixPage.scss';
@import './LiveSession.scss';
@import './getInTouchPage.scss';
@import './siteLoadingErrorPage.scss';

@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&display=swap');

html,
body,
#root,
.App {
  height: 100%;
  font-family: "Exo", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  background-color: $custom-black;
}

input, 
textarea, 
button {
  font-family: "Exo", sans-serif;
  box-sizing: border-box;
  padding:$spacing-m; 
  width: 100%;
  font-size: large;
  border: 2px solid $custom-black;
  border-radius: 0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $custom-white;  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $custom-white; 
}

.App {
  text-align: center;
  position: relative;

  .page {
    margin: 0 $spacing-m;
  }

  img {
    width: 100%;
  }

  ol,
  ul {
    list-style-type: none;
  }

  .error,
  .success {
    padding: $spacing-s;
    background-color: $success;
    border-radius: $spacing-s;
  }

  .error {
    background-color: $error;
  }

  .email-error {
    margin-top: $spacing-s;
    color: $link-color-selected;
  }

  .email-error-field {
    border: 2px solid $link-color-selected;
  }

}

@media only screen and (min-width: $bp-mobile-max) {
  .page {
    margin: auto;
    width: 80%;
    text-align: center;
  }
}

@import './FormComponents.scss';
@import './Buttons.scss';
@import './genericAppList.scss';
