@import '../../styles/variables.scss';

.header-nav {
  position: fixed;
  z-index: 35;
  top: 0;
  left: 0;
  text-align: left;
  margin: auto;

  ul.nav-icon {
    padding: 0;
    margin: $spacing-sm;
    cursor: pointer;

    li svg {
      width: $spacing-l;
      height: $spacing-l;
      fill: $link-color-selected;
      transition: fill $transition-timing ease-in-out;

      &:hover {
        svg {
          fill: $custom-white;
        }
      }
    }
  }
}

@keyframes navAnimation {
  from {left: $nav-default-position} 
  to{left: 0;} 
}

@media only screen and (min-width: $bp-mobile-max) {
  .header-nav {
    ul.nav-icon {
      li svg {
        width: $spacing-xl;
        height: $spacing-xl;
        fill: $link-color-selected;
      }
    }
  }
}
