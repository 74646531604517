@import '../../styles/variables.scss'; 

.modal-container {
    box-sizing: border-box;
    position: fixed;
    z-index: 35;
    top: $spacing-m;
    left: $spacing-m;
    right: $spacing-m;
    bottom: $spacing-m;
    background-color: $custom-black;
    opacity: 0.95;
    border: 2px solid $custom-white;

    button {
        position: absolute;
        top: $spacing-m;
        right: $spacing-m;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        width: $spacing-ml;
        height: $spacing-ml;

        svg {
            fill: $link-color-selected;  
            transition: fill $transition-timing ease-in-out;      
        }

        &:hover {
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0;

            svg {
                fill: $custom-white;      
            }
        }
    }

    h2 {
        font-size: 6em;
    }
}


@media only screen and (min-width: $bp-mobile-max) {
    .modal-container {
        button {
            position: absolute;
            top: $spacing-m;
            right: $spacing-m;
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0;
            width: $spacing-l;
            height: $spacing-l;
        }
    }
  }