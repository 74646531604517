@import '../../styles/variables.scss';

.mix-list {
    margin: $spacing-xxl 0 0 0; 
    padding: 0 $spacing-s 0;
    text-align: left;

    li {
        text-align: left;
        padding: 0 0 $spacing-l 0;
        margin: 0 0 $spacing-l 0;
        border-bottom: 1px solid $custom-white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        &:last-child {
            border-bottom: none;
        }

    }
}

@media only screen and (min-width: $bp-mobile-max) {
    .mix-list {
        li {
            align-items: top;
            justify-content: flex-start;

            h2.show-mobile {
                display: none;
            }
        
            h2.show-desktop {
                display: block;
            } 
        }
    }
}