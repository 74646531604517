@import '../../styles/variables.scss';

li {
    .mix-item-details {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h2.show-desktop {
            display: none;
        }
    }
}

@media only screen and (min-width: $bp-mobile-max) {

    li {   
        .mix-item-details {
            h2.show-desktop {
                display: block;
            }
        }
    }

}
