@import '../styles/variables.scss';

form {
  text-align: left;
}

label {
  display: block;
  padding: $spacing-m 0 $spacing-m;
}

select,
input[type=text] {
  box-sizing: border-box;
  border: 1px solid $custom-grey;
  border-radius: $spacing-s;
  padding: $spacing-m;
  width: 100%;
  font-size: $font-m;
  cursor: pointer;
}

input[type=checkbox] {
  width: 30px;
  height: 30px;
  margin-right: $spacing-m;
}

@media only screen and (min-width: $bp-mobile-max) {
  select,
  input[type=text] {
    width: 80%;
  }
}