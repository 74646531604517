@import './variables.scss';

button {
  box-sizing: border-box;
  border: 1px solid $custom-grey;
  border-radius: 0;
  padding: $spacing-m;
  width: 100%;
  font-size: $font-m;
  cursor: pointer;

  &:hover {
    background: $custom-grey;
  }

  // @media only screen and (min-width: $bp-mobile-max) {
  //   width: 40%;
  // }
}
